<template>
  <v-tab-item>
    <v-card-text class="pa-6">
      <v-card flat :loading="loading" :disabled="loading">
        <template v-if="jornadas_trabalho.length && !loading">
          <v-row
            v-for="(jornada, index) in jornadas_trabalho"
            :key="index"
            justify="center"
          >
            <!-- Dia Semana -->
            <v-col cols="12" xl="2">
              <v-text-field
                v-model="jornada.dia_semana"
                label="Dia da Semana"
                dense
                hide-details
                readonly
              ></v-text-field>
            </v-col>

            <!-- Entrada -->
            <v-col cols="12" xl="1">
              <v-text-field
                v-model="jornada.entrada"
                label="Entrada"
                type="time"
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <!-- Saida Intervalo -->
            <v-col cols="12" xl="1">
              <v-text-field
                v-model="jornada.saida_intervalo"
                label="Saida Intervalo"
                type="time"
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <!-- Volta Intervalo -->
            <v-col cols="12" xl="1">
              <v-text-field
                v-model="jornada.volta_intervalo"
                label="Volta Intervalo"
                type="time"
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <!-- Saída -->
            <v-col cols="12" xl="1">
              <v-text-field
                v-model="jornada.saida"
                label="Saída"
                type="time"
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </template>

        <div v-else-if="!loading" class="">
          <v-alert outlined>
            <h3>
              Nenhuma jornada de trabalho sincronizada com esse colaborador!
            </h3>

            <p class="mt-3">
              Para cadastrar uma jornada de trabalho, é necessário verificar se
              o colaborador tem um cargo registrado, o controle da jornada de
              trabalho está dentro do cargo. Caso não esteja, é necessário
              cadastrar um cargo para o colaborador e configurar a jornada de
              trabalho no cargo. Após isso é necessário sincronizar a jornada
              cadastrada no cargo com o colaborador. O botão para sincronizar
              encontra-se dentro da página de cargos.
            </p>
          </v-alert>
        </div>
      </v-card>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="px-6 py-4">
      <v-btn :to="{ path: '/gestor/dados/equipe' }" exact> Voltar </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="salvarJornadaTrabalho()"
        color="secondary"
        class="mr-1 white--text"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-tab-item>
</template>

<script>
import {
  fetchJornadasTrabalho,
  updateJornadasTrabalho,
} from "@/api/gestor/equipe_jornada_trabalho.js";

export default {
  name: "JornadaTrabalho",

  props: {
    cargo: {
      type: Object,
    },
  },

  data() {
    return {
      loading: true,
      jornadas_trabalho: [],
    };
  },

  computed: {
    empresa_equipe_id() {
      return this.$route.params.equipeId;
    },
  },

  methods: {
    async getJornadasTrabalho() {
      try {
        const response = await fetchJornadasTrabalho(this.empresa_equipe_id);
        if (response.length) {
          this.jornadas_trabalho = response;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async salvarJornadaTrabalho() {
      try {
        this.loading = true;
        const response = await updateJornadasTrabalho({
          jornadas_trabalho: this.jornadas_trabalho,
        });
        if (response.status === 201) {
          this.$toast.success("Jornada de trabalho atualizada com sucesso!");
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    this.loading = true;
    await this.getJornadasTrabalho();
    this.loading = false;
  },
};
</script>

<style>
</style>
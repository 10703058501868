import api from "../axios_service.js";

const fetchJornadasTrabalho = async (empresa_equipe_id) => {
  const response = await api.get(`/gestor/equipe-jornada-trabalho?empresa_equipe_id=${empresa_equipe_id}`);
  return response.data.result;
};

const updateJornadasTrabalho = (body) => {
  return api.post(`/gestor/equipe-jornada-trabalho/update`, body);
};

export { fetchJornadasTrabalho, updateJornadasTrabalho };
